import React from 'react'; 
import SEO from '../components/seo';
import Layout from '../components/layout'; 
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import FAQ from '../components/FAQ'; 
import Certifications, { GAFCERT, OCCERT } from '../components/Certifications';
import ReviewSitesInlineRatings from '../components/ReviewSitesInlineRatings';
import HeroRatingStars from '../components/hero-rating-stars';
import Button from '../components/atoms/Button';
import { ArrowRight } from 'lucide-react'; 
import MaterialsMatterVideo from '../components/MaterialsMatterVideo'; 
import WhyUsSection from '../components/WhyUsSection';
import { products as ProductItems } from '../components/product-gallery';

function Products(props) {
  return (
    <Layout pageProps={props}>
      <SEO
        title={'High Quality Products'}
        description={
          'Top Tier Roofing is proud to offer the highest quality roofing products on the market. Our team is dedicated to providing you with the best materials and products to ensure your home is protected for years to come. We offer a wide range of roofing materials, including asphalt shingles, metal roofing, and more. Our team will work with you to find the perfect roofing solution for your home. Contact us today to learn more about our products and services.'
        }
        keywords={[]}
        robots={'index follow'}
      />

      <div className="relative isolate">
        <svg
          className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M.5 200V.5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect width="100%" height="100%" strokeWidth={0} fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" />
        </svg>
        <div
          className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
          aria-hidden="true"
        >
          <div
            className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#0071b8] to-[#0071b8] opacity-30"
            style={{
              clipPath:
                'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
            }}
          />
        </div>
        <div className="overflow-hidden">
          <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-32 lg:px-8 lg:pt-8">
            <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center py-2 md:py-16">
              <div className="relative w-full md:w-1/2 max-w-xl lg:shrink-0 xl:max-w-2xl">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Our Products</h1>
                <p className="mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
                  Discover the lasting impact of superior materials with Top Tier Roofing. Our commitment to using
                  premium GAF shingles ensures your roof isn't just a structure, but a long-term investment in your
                  home's safety and aesthetics. With Top Tier Roofing, you get more than just expert installation; you
                  gain the assurance of durability and performance, where quality and value meet to protect what matters
                  most to you.
                </p>
                <div className="mt-10 flex items-center gap-x-6">
                  <ReviewSitesInlineRatings />
                </div>
                <div className="mt-10">
                  <Button color="brandGradient" size="xl" to="/contact">
                    Get a FREE Estimate <ArrowRight size={18} />
                  </Button>
                </div>
              </div>

              <div className="mt-8 md:mt-0 flex-1 w-2/3  w-full">
                <MaterialsMatterVideo />
                <div className="font-display p-8 text-center flex">
                  <div>
                    <div className="flex items-center mb-2 mx-auto justify-center space-x-2 ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        class="w-5 h-5 text-brand-600"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M2 10a8 8 0 1 1 16 0 8 8 0 0 1-16 0Zm6.39-2.908a.75.75 0 0 1 .766.027l3.5 2.25a.75.75 0 0 1 0 1.262l-3.5 2.25A.75.75 0 0 1 8 12.25v-4.5a.75.75 0 0 1 .39-.658Z"
                          clip-rule="evenodd"
                        />
                      </svg>

                      <p className="uppercase text-brand-600 font-semibold tracking-wide text-sm mt-1 ">Video</p>
                    </div>
                    <p className="text-gray-400 font-normal">
                      Learn how Top Tier Roofing emphasizes the importance of high-quality materials like GAF shingles
                      to ensure durability and value, making no compromises on your home's protection.
                    </p>
                  </div>
                </div>{' '}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto  max-w-7xl px-6 lg:px-8 p-12   relative">
        <div className="mx-auto max-w-4xl">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">
            Materials <span className="text-brand-600">Matter</span> 
          </h2>
          <p className="mt-4 text-base leading-7 text-neutral-500  text-center">
The Top Tier product gallery features a wide range of high-quality materials and products to ensure your home is protected for years to come.
          </p>
        </div>
        <div className="max-w-7xl  mx-auto flex flex-wrap mx-auto  mt-6">
        {ProductItems.map((item, j) => {
          return (
            <div key={j} className="w-1/4 md:w-1/6 lg:w-[16%]">
              <div className="p-2">
                <div className="relative rounded-lg  ">
                  {item.component}
                  <p className="text-sm font-medium text-neutral-800 font-display py-3 px-2">{item.name}</p>
                </div>{' '}
              </div>
            </div>
          );
        })}  </div>
      </div>
      <Certifications/>
      <WhyUsSection />

      {/* <div className="mx-auto max-w-7xl px-6  lg:px-8 py-12 md:py-16">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">History</h2>
          <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row justify-between">
            <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
              <div className="ax-w-xl text-base leading-7 text-gray-700 prose ">content here</div>
            </div>
            <div className="w-full md:w-1/3  flex-col flex">
              <div className="bg-white shadow-md rounded-xl divide-y  space-y-8 py-4">
                <GAFCERT />

                <OCCERT />
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <FAQ />
    </Layout>
  );
}

export default Products;
