import React,{ useRef, useState } from "react"; 
import Button from "./atoms/Button"; 
import { ArrowRight } from "lucide-react"; 
import { StaticImage } from "gatsby-plugin-image"
 
const MaterialsMatterVideo = ({ title, classnames }) => {
 
  
  const videoElement = useRef();
  const [isOpen, updateIsOpen] = useState(false);
  const playVideo = () => {
    updateIsOpen(true);
    try {
      videoElement.current.play();
    } catch (e) {
      console.log("error playing video", "browser may not support media API");
    }
  };
  return (
    <div  className=" rounded-xl overflow-hidden shadow-xl border border-gray-200  w-full  ">
      
      <div>
        <div className={`relative  h-full w-full bg-white`}>
          <span
            className={" absolute w-full h-full play-overlay m-auto " + (!isOpen ? "block" : "hidden")}
            style={{
              zIndex: 10,
              cursor: "pointer",
            
            }}
            onClick={playVideo}
          >
            <span className="play-button-overlay hidden md:block">
              <img src="/assets/play.svg" onClick={playVideo} alt="Play Video" loading="lazy"  className="-mt-8"/>
            </span>
          </span>
          {/* <StaticImage src={"../images/products/timberlinehdz_charcoal_shingles-min.jpeg"}  className={`h-full w-full block mb-0`} alt="Product image"  onClick={playVideo}/> */}
 
         
            <video
              ref={videoElement}
              controls={true}
              name="media"
              preloaded="none"
              className={` w-full  h-full  `}
              poster={'/assets/materials-matter.jpeg'}
              autoPlay={false}
            >
              <source src={'https://videos.ctfassets.net/7h2p3b5vvfl2/2dVYxPJKFnINxfpn0Hp1FV/5358952a56624d261fbdfd3805d8e4f7/materials_matter.mp4'} type={'video/mp4'} />
              Your browser does not support the video tag.
            </video>

      
        </div>
       
      </div>
 
      
      
    </div>
  );
};
 

export default MaterialsMatterVideo;
